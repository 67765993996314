<template>
<div class="main-container">
  <Header />

  <TopCarousel :sliders="slider.RutaUAgents" />

  <AboutSectionFour :data="rutauagents.about" :title="'¿Qué es Ruta-U Agentes?'" />

  <!--Uses and Implementatios section-->
  <!--<ProjectGridSlider
      :data="rutauagents.owrClients"
      :title="'Nuestros Clientes'"
      :height="'height200'"
    />-->

  <div>
    <img src="/assets/img/banners/rutauagents/4.jpg" style="background-size: cover;width: 100%;" />
  </div>

  <!--Our clients section-->
  <LatestProject :data="rutauagents.implementations" :title="'Nuestros Servicios'" />

  <!--Advantage-->
  <div class="page-wrapper section-space--inner--60">
    <div class="service-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="text-center" style="padding-bottom: 20px;">
              Ventajas competitivas
            </h2>
            <div class="service-details">
              <div class="content section-space--top--30">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <img style="max-width: 95%;" src="/assets/img/banners/rutauagents/6.jpg" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="team-job__content-wrapper">
                      <div class="team-job__list-wrapper">
                        <div class="team-job__single">
                          <h3 class="title">
                            Red de Franquiciados
                          </h3>
                          <p class="text" style="text-align: justify;">
                            La primera App en Venezuela que presta servicios
                            de delivery a través de un esquema de
                            franquiciados a nivel nacional.
                          </p>
                        </div>
                        <div class="team-job__single">
                          <h3 class="title">
                            Múltiples medios de entrega
                          </h3>
                          <p class="text" style="text-align: justify;">
                            Se ha configurado la posibilidad de que cada
                            transportista cuente con distintos medios de
                            entrega.
                          </p>
                        </div>
                        <div class="team-job__single">
                          <h3 class="title">
                            Ruta-U LockerSys
                          </h3>
                          <p class="text" style="text-align: justify;">
                            Un novedoso sistema de lockers con tecnología QR
                            Ruta-U para apoyar la gestión de delivery en en
                            Centros Comerciales y Edificios de Oficina.
                            Primeros en Venezuela.
                          </p>
                        </div>
                        <div class="team-job__single">
                          <h3 class="title">
                            Múltiples Servicios
                          </h3>
                          <p class="text" style="text-align: justify;">
                            Ruta-U es una aplicación con capacidad de
                            conectarse a otras plataformas del ecosistema de
                            LikeUGroup, y otras de terceros, además de prestar
                            de forma directa servicios de delivery, taxis,
                            grúas y mudanzas. Es una aplicación integral.
                          </p>
                        </div>
                        <div class="team-job__single">
                          <h3 class="title">
                            Soporte y Servicio
                          </h3>
                          <p class="text" style="text-align: justify;">
                            Ruta-U cuenta con su propio gestor de
                            comunicaciones entre los clientes y
                            transportistas, además de contar con un centro de
                            soporte de operaciones de LikeU Group, para
                            garantizar la operación permanente, 24/7/365.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <img src="/assets/img/banners/rutauagents/2.png" style="background-size: cover;width: 100%;" />
  </div>

  <CarouselPlanSection2 :title="'Planes de Afiliación'" :subtitle="'Suscríbete al modelo de negocio que deseas'" :platform="17" :plse_type="'delivery'" />

  <div>
    <img src="/assets/img/banners/rutauagents/5.jpg" style="background-size: cover;width: 100%;" />
  </div>

  <!--<div class="breadcrumb-area" style="background-image: url(assets/img/banners/rutaubusiness/2.png);width: 100%;padding: 0px;">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-6">
          <h2 class="text-right" style="color:#fff;">
            Descarga próximamente aquí
          </h2>
        </div>
        <div class="col-6">
          <div class="row align-items-center">
            <a href="#" style="margin: auto;">
              <img style="background-size: cover;width: 70%;" src="/assets/img/icons/google-play-badge.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>-->

  <div class="breadcrumb-area bg-img" style="background-image: url(assets/img/banners/payforu/f3.jpg);width: 100%;height: auto;">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <h3 class="text-center" style="color:#fff;font-size: 30px;">
            Contrata el servicio y descarga nuestra APP
          </h3>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="row">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.likeugroup.rutau" style="margin:auto">
              <button class="btn ht-btn--round" style="background-color: #003d74;">
                ¡Descárgala aquí!
              </button>
            </a>
            <!--<a href="/assets/app/rutau.apk" style="margin:auto">
              <button class="btn ht-btn--round" style="background-color: #003d74;">
                ¡Descárgala aquí!
              </button>
            </a>-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="about-section section-space--inner--60" id="contact-form">
    <div class="container">
      <div class="row row-25">
        <div class="col-12">
          <h2 class="subtitle text-center" style="font-weight: bold;">
            Preguntas frecuentes
          </h2>
        </div>
        <div class="col-lg-6 col-12 mt--30" style="padding-top: 20px;">
          <div v-for="item in rutauagents.FAQ" :key="item.id">
            <Accordion :question="item.question" :answer="item.answer" />
          </div>
        </div>
        <div class="col-lg-6 col-12 mb-30">
          <ContactForm :platform="17" />
        </div>
      </div>
    </div>
  </div>

  <div class="about-section section-space--inner--20">
    <div class="container">
      <div class="row row-25">
        <div class="col-12">
          <h2 class="subtitle text-center" style="font-weight: bold;">
            Conoce nuestro Ecosistema Digital
          </h2>
        </div>
      </div>
    </div>
  </div>

  <Carousel2 :data="slider.ecosystem" />

  <BrandCarousel addClass="grey-bg" />

  <Footer />

  <OffCanvasMobileMenu />

  <!-- WhatsApp Bottom -->
  <div id="whatsapp">
    <a href="https://api.whatsapp.com/send?phone=+584142698744&text=¡Hola!%20Quiero%20más%20información%20acerca%20de%20sus%20servicios." title="Escríbenos por Whastapp" data-tap="tooltip">
      <i class="fa fa-whatsapp" style="color: #ffffff;" aria-hidden="true"></i>
    </a>
  </div>

  <!-- back to top start -->
  <back-to-top class="scroll-top" bottom="100px" right="10px">
    <i class="ion-android-arrow-up"></i>
  </back-to-top>
  <!-- back to top end -->
</div>
</template>

<script>
import slider from "../data/slider.json";
import data from "../data/service.json";
import rutauagents from "../data/rutauagents.json";
import Header from "@/components/Header";
import AboutSectionFour from "../components/sections/AboutSectionFour";
import HeroTwo from "../components/sections/HeroTwo";
import Carousel1 from "../components/sections/Carousel1";
import TopCarousel from "../components/TopCarousel";
import Carousel2 from "../components/sections/Carousel2";
import ProjectGridSlider from "@/components/sections/ProjectGridSlider";
import Breadcrumb from "../components/Breadcrumb";
import TeamMember from "../components/sections/TeamMember";
import LatestProject from "../components/sections/LatestProject";
import Accordion from "../components/Accordion";
import ContactForm from "../components/ContactForm";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
import ServiceDetailsSidebar from "@/components/ServiceDetailsSidebar";
import ServiceDetailsMain from "@/components/ServiceDetailsMain";
import CarouselPlanSection2 from "@/components/sections/CarouselPlanSection2";

export default {
  components: {
    Header,
    HeroTwo,
    LatestProject,
    Breadcrumb,
    Accordion,
    ContactForm,
    TopCarousel,
    ServiceDetailsMain,
    ProjectGridSlider,
    TeamMember,
    AboutSectionFour,
    ServiceDetailsSidebar,
    Carousel1,
    Carousel2,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu,
    CarouselPlanSection2
  },
  data() {
    return {
      config: {
        headers: {
          Authorization: ""
        }
      },
      data,
      slider,
      rutauagents,
      orderData: "",
      user_id: ""
    };
  },
  beforeCreate() {
    if (sessionStorage.getItem("country") === null) {
      sessionStorage.setItem("country", "057-venezuela");
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.breadcrumb-area {
  min-height: 0px;
}

.swiper-wrapper {
  padding: 0px !important;
}

.login-form {
  min-height: 200px;
  align-items: center;
  justify-content: center;
}

.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: -10%;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin: auto;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom !important;
  background-color: #e94e1b;
}

.breadcrumb-area::before {
  min-height: 0px;
  background-color: #00000000;
}

#whatsapp {
  cursor: pointer;
  position: fixed;
  right: 10px;
  bottom: 20px;
  display: block;
  color: #ffffff;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  background-color: #00e676;
}

@media only screen and (max-width: 479px) {
  .scroll-top {
    right: 10px !important;
    bottom: 80px !important;
  }

  #whatsapp {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #202020;
  font-weight: 600;
  line-height: 35px !important;
}
</style>
